import {Router, Redirect} from '@reach/router';
import React, {useEffect, useState} from 'react';
import QRCode from 'qrcode.react';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';

import {translations, getBrowserLocales} from '../i18n';

import HyGeniusLogo from '../images/HyGenius_Logo.png';
import aquaFreeLogo from '../images/aqua_free.png';

const IndexPage = () => {
  return (
    <Router basepath="/">
      <HomePage path="/" />
      <HomePage path="token/:token" />
      <HomePage path="resetToken/:token" />
      <Redirect noThrow from="/*" to="/" />
    </Router>
  );
};

export default IndexPage;

const HomePage = (props) => {
  const [t, setTranslation] = useState(translations['de']);
  const [resetPassword, setResetPassword] = useState(false);

  useEffect(() => {
    const currentURL = new URL(document.URL);

    if (currentURL.pathname === '/') {
      let path = document.getElementById('hide-qr-section');
      path.style.display = 'none';
    }

    if (currentURL.pathname.indexOf('/resetToken') === 0) {
      console.log('It works!');
      setResetPassword(true);
    }

    const locales = getBrowserLocales({languageCodeOnly: true});
    for (let i = 0; i < locales.length; i++) {
      const l = locales[i];
      if (translations[l]) {
        setTranslation(translations[l]);
        break;
      }
    }

    const qrCode = document.querySelector('#qr-code-wrapper');
    scrollIntoView(qrCode, {behavior: 'smooth', scrollMode: 'if-needed'});
  }, []);

  return (
    <div id="wrapper" className="wrapper">
      <div className="intro-wrapper">
        <div className="header">
          <img id="HyGeniusLogo" src={HyGeniusLogo} alt="HyGenius Logo" />
          <div className="heading">
            {t['header.one']}
            <br />
            {t['header.two']}
            <br />
            {t['header.three']}
          </div>
          <div className="sub-heading">
            {t['header.subheadingOne']} <em>{t['header.subheadingTwo']}</em>
            <br />
            {t['header.subheadingThree']}
          </div>
        </div>
        <img id="HyGeniusLogoDigital" src={HyGeniusLogo} alt="HyGenius Logo" />
        <img id="HyGeniusAppLogo" src={t['logo.app']} alt="HyGenius App Logo" />
        <div className="header-mobile">
          {t['header.one']}
          <br />
          {t['header.two']}
          <br />
          {t['header.three']}
        </div>
      </div>
      <div className="links-background">
        <div className="links-wrapper">
          <a
            href={t['link.app-store']}
            target="_blank"
            rel="noopener noreferrer">
            <img
              className="store-logo"
              src={t['logo.ASBagde']}
              alt="Apple Store Logo"
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.aquafree.servicesapp"
            target="_blank"
            rel="noopener noreferrer">
            <img
              className="store-logo"
              src={t['logo.GPSBadge']}
              alt="Google Play Logo"
            />
          </a>
        </div>
      </div>
      <div className="register-wrapper">
        {!resetPassword && (
          <div id="hide-qr-section">
            <div className="register-heading">
              <h1>{t['header.register']}</h1>
              <p>{props.token}</p>
            </div>
            <div id="instruction-qr-wrapper" className="instruction-qr-wrapper">
              <div>
                <div className="qr-instruction one">
                  <div>
                    <div className="circle">1</div>
                  </div>
                  <p>{t['instruction.one']}</p>
                </div>
                <div className="qr-instruction">
                  <div>
                    <div className="circle">2</div>
                  </div>
                  <p>{t['instruction.two']}</p>
                </div>
              </div>
              <div id="qr-code-wrapper">
                <QRCode
                  value={`https://app.aqua-free-services.com/token/${props.token}`}
                  size={220}
                  fgColor="#003282"
                />
              </div>
            </div>
          </div>
        )}
        {resetPassword && (
          <div id="hide-qr-section">
            <div className="register-heading">
              <h1>{t['header.reset']}</h1>
              <p>{props.token}</p>
            </div>
            <div id="instruction-qr-wrapper" className="instruction-qr-wrapper">
              <div>
                <div className="qr-instruction one">
                  <div>
                    <div className="circle">1</div>
                  </div>
                  <p>{t['instruction.one']}</p>
                </div>
                <div className="qr-instruction">
                  <div>
                    <div className="circle">2</div>
                  </div>
                  <p>{t['instruction.twoReset']}</p>
                </div>
              </div>
              <div id="qr-code-wrapper">
                <QRCode
                  value={`https://app.aqua-free-services.com/resetToken/${props.token}`}
                  size={220}
                  fgColor="#003282"
                />
              </div>
            </div>
          </div>
        )}

        <div className="flex">
          <img src={aquaFreeLogo} id="AquaFreeLogo" alt="Aqua free Logo" />
          <p id="marketing-slogan">{t['slogan.marketing']}</p>
        </div>
      </div>

      <div className="footer-background">
        <div className="footer">
          <div id="contact-wrapper" className="contact-wrapper">
            <div id="contact">
              <div className="first-line-contact">
                Aqua <div className="free">free</div> GmbH
              </div>
              {t['contact.phone']}
              <br />
              {t['contact.email']}
            </div>
          </div>
          <div className="links-impressum-datenschutz">
            <div>
              <a
                className="link-impr-daten"
                target="_blank"
                rel="noopener noreferrer"
                href="https://production-raf-static.s3.eu-central-1.amazonaws.com/DSGVO/DE/DatenschutzerklaerungApp.html">
                {t['link.dataPrivacy']}
              </a>
            </div>
            <div>
              <a
                className="link-impr-daten"
                target="_blank"
                rel="noopener noreferrer"
                href="https://production-raf-static.s3.eu-central-1.amazonaws.com/impressum/index.html">
                {t['link.imprint']}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

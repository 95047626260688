import HyGeniusAppGerman from './images/HyGenius_App_DE.png';
import HyGeniusAppEnglish from './images/HyGenius_App_EN.png';
import ASBadgeGerman from './images/AS_Badge_German.svg';
import GPSBadgeGerman from './images/GPS_Badge_German.svg';
import ASBadgeEnglish from './images/AS_Badge_English.svg';
import GPSBadgeEnglish from './images/GPS_Badge_English.svg';
import ASBadgeFrench from './images/AS_Badge_French.svg';
import GPSBadgeFrench from './images/GPS_Badge_French.svg';
import ASBadgeSpanish from './images/AS_Badge_Spanish.svg';
import GPSBadgeSpanish from './images/GPS_Badge_Spanish.svg';
import ASBadgeDutch from './images/AS_Badge_Dutch.svg';
import GPSBadgeDutch from './images/GPS_Badge_Dutch.svg';

export const translations = {
  de: {
    'header.one': 'Einfach digital,',
    'header.two': 'einfach sicher,',
    'header.three': 'einfach einfach.',
    'header.subheadingOne': 'Ihr persönlicher',
    'header.subheadingTwo': '',
    'header.subheadingThree': 'Wasserfilter-Assistent',
    'header.register': 'Ihr Registrierungscode:',
    'header.reset': 'Passwort zurücksetzen:',
    'instruction.one':
      'Den QR-Code mit der Kamera App des Handys, auf dem die HyGenius® App installiert ist, scannen.',
    'instruction.two': 'Die Registrierung in der HyGenius® App abschließen.',
    'instruction.twoReset':
      'Die Änderung ihres Passworts in der HyGenius® App abschließen.',
    'slogan.marketing': 'Ihr Spezialist für Wasserhygiene.',
    'contact.phone': 'Tel: +49 40 46899990',
    'contact.email': 'info@aquafree.com',
    'link.dataPrivacy': 'DATENSCHUTZ',
    'link.imprint': 'IMPRESSUM',
    'link.app-store': 'https://apps.apple.com/de/app/id1532652215',
    'logo.app': HyGeniusAppGerman,
    'logo.GPSBadge': GPSBadgeGerman,
    'logo.ASBagde': ASBadgeGerman
  },
  en: {
    'header.one': 'Simply digital,',
    'header.two': 'simply secure,',
    'header.three': 'simply simple.',
    'header.subheadingOne': 'Your personal Aqua',
    'header.subheadingTwo': 'free',
    'header.subheadingThree': 'water filter assisstant',
    'header.register': 'Your registration code:',
    'header.reset': 'Reset your password:',
    'instruction.one':
      'Scan the QR code with your device on which the HyGenius® app is installed.',
    'instruction.two': 'Complete the registration in the HyGenius® app.',
    'instruction.twoReset':
      'Complete reseting your password in the HyGenius® app.',
    'slogan.marketing': 'Your specialist for water hygiene.',
    'contact.phone': 'Phone: + 44 (0)1484 483045',
    'contact.email': 'm.aristidou@aqua-free.com',
    'link.dataPrivacy': 'DATA PRIVACY',
    'link.imprint': 'IMPRINT',
    'link.app-store': 'https://apps.apple.com/app/id1532652215',
    'logo.app': HyGeniusAppEnglish,
    'logo.GPSBadge': GPSBadgeEnglish,
    'logo.ASBagde': ASBadgeEnglish
  },
  fr: {
    'header.one': 'Simplement',
    'header.two': 'numérique,',
    'header.three': 'simplement sûr.',
    'header.subheadingOne': "Votre assistant personnel d'eau",
    'header.subheadingTwo': '',
    'header.subheadingThree': '',
    'header.register': "Votre code d'enregistrement:",
    'header.reset': 'Réinitialiser le mot de passe',
    'instruction.one':
      "Scannez le code QR avec l'application appareil photo du téléphone mobile sur lequel l'application HyGenius® est installée.",
    'instruction.two':
      "Terminez l'enregistrement dans l'application HyGenius®.",
    'instruction.twoReset':
      "Terminez la réinitialisation de votre mot de passe dans l'application HyGenius®.",
    'slogan.marketing': "Votre spécialiste de l'hygiène de l'eau.",
    'contact.phone': 'Télé: +33 (0)3 87 20 02 30',
    'contact.email': 'info@aqua-free.fr',
    'link.dataPrivacy': 'PROTECTION DES DONNÉES',
    'link.imprint': 'MENTIONS LÉGALES',
    'link.app-store': 'https://apps.apple.com/fr/app/id1532652215',
    'logo.app': HyGeniusAppEnglish,
    'logo.GPSBadge': GPSBadgeFrench,
    'logo.ASBagde': ASBadgeFrench
  },
  es: {
    'header.one': 'Simplemente',
    'header.two': 'digital,',
    'header.three': 'simplemente fácil.',
    'header.subheadingOne': 'Tu asistente personal de agua',
    'header.subheadingTwo': '',
    'header.subheadingThree': '',
    'header.register': 'Tu código de registro:',
    'header.reset': 'Restablecimiento de contraseña:',
    'instruction.one':
      'Escanee el código QR con la aplicación de la cámara del teléfono móvil en el que está instalada la aplicación HyGenius®.',
    'instruction.two': 'Complete el registro en la aplicación HyGenius®.',
    'instruction.twoReset':
      'Complete el restablecimiento de su contraseña en la aplicación HyGenius®.',
    'slogan.marketing': 'Su especialista en higiene del agua.',
    'contact.phone': 'Tel: +34 876 643 188',
    'contact.email': 'info@aqua-free.es',
    'link.dataPrivacy': 'PROTECCIÓN DE DATOS',
    'link.imprint': 'IMPRIMIR',
    'link.app-store': 'https://apps.apple.com/es/app/id1532652215',
    'logo.app': HyGeniusAppEnglish,
    'logo.GPSBadge': GPSBadgeSpanish,
    'logo.ASBagde': ASBadgeSpanish
  },
  nl: {
    'header.one': 'Digitaal,',
    'header.two': 'veilig,',
    'header.three': 'en makkelijk.',
    'header.subheadingOne': 'Uw persoonlijke',
    'header.subheadingTwo': '',
    'header.subheadingThree': 'Waterfilter-assistent',
    'header.register': 'Uw registratiecode:',
    'header.reset': 'Wachtwoord reset',
    'instruction.one':
      'Gebruik hiervoor de mobiele telefoon waarop de HyGenius app is geïnstalleerd.',
    'instruction.two': 'Voltooi de registratie in de HyGenius®-app.',
    'instruction.twoReset':
      'Voltooi het opnieuw instellen van uw wachtwoord in de HyGenius®-app.',
    'slogan.marketing': 'Uw specialist voor waterhygiëne.',
    'contact.phone': 'Tel: +31 (0) 40 3050092',
    'contact.email': 'Salesnl@aqua-free.com',
    'link.dataPrivacy': 'GEGEVENSBESCHERMING',
    'link.imprint': 'AFDRUK',
    'link.app-store': 'https://apps.apple.com/nl/app/id1532652215',
    'logo.app': HyGeniusAppEnglish,
    'logo.GPSBadge': GPSBadgeDutch,
    'logo.ASBagde': ASBadgeDutch
  }
};

export function getBrowserLocales(options = {}) {
  const defaultOptions = {
    languageCodeOnly: false
  };

  const opt = {
    ...defaultOptions,
    ...options
  };

  const browserLocales =
    navigator.languages === undefined
      ? [navigator.language]
      : navigator.languages;

  if (!browserLocales) {
    return undefined;
  }

  return browserLocales.map((locale) => {
    const trimmedLocale = locale.trim();

    return opt.languageCodeOnly ? trimmedLocale.split(/-|_/)[0] : trimmedLocale;
  });
}
